// Do not print
//
// Removes an element from the print version of the web site.
//
// By importing these CSS rules in a file marked as media "all", we allow these
// print rules to be aggregated with other stylesheets, for improved front-end
// performance.

.print-none,
%print-none {
  @media print {
    display: none;
  }
}
